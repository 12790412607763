<script setup lang="ts">
import type { Game } from "@/types";

const props = withDefaults(
	defineProps<{
		games: Game[];
		rows: number | null;
		withGameOfWeek?: boolean;
		isFavoriteGames?: boolean;
		skipGamePreview?: boolean;
		withAnimation?: boolean;
	}>(),
	{
		rows: null,
		withAnimation: false
	}
);

const { games: allGames } = useHomePage();
const { data: appInit } = useAppInitData();
const { closeAll, isOpen, open } = useTaoModals();
const { gameofWeek } = useHomePage();
const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const prepareImgUrl = useImage();
const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;

const gameOfTheWeek = computed(() => {
	const gameOfTheWeek = allGames.value?.find((game) => game.id === gameofWeek?.value?.id);
	const gameOfTheWeekFromList = props.games?.find((game) => game.id === gameofWeek?.value?.id);

	if ((!gameOfTheWeekFromList && props.isFavoriteGames) || !gameOfTheWeek) {
		return null;
	}

	const img =
		(gameofWeek.value as { image2x?: string; image?: string })?.image2x ||
		(gameofWeek.value as { image2x?: string; image?: string })?.image;

	return {
		...gameOfTheWeek,
		img
	};
});

const filteredGames = computed(() => {
	if (props.withGameOfWeek && gameOfTheWeek.value) {
		return props.games?.filter((game) => game.id !== gameofWeek?.value?.id);
	}

	return props.games;
});

const { handleToggleToFavoriteClick } = useAddToFavorite();

const { handleOpenGame } = useOpenGame(open);

const handlePlay = (game: Game) => {
	closeAll();

	nextTick(() => {
		if (isOpen("LazyOModalGame") && !appInit.value?.isGuest) {
			open("LazyOModalGame");
		}
		handleOpenGame(game?.slug, "open_game");
	});
};
</script>

<template>
	<transition-group
		:name="withAnimation ? 'list-complete' : ''"
		:class="['grid-cards', { [`grid-cards--${rows}`]: rows, 'list-complete': withAnimation }]"
		tag="div"
	>
		<MGame
			v-if="withGameOfWeek && gameOfTheWeek"
			key="gameOfTheWeek"
			class="week-game"
			:is-guest="!!appInit?.isGuest"
			:game="gameOfTheWeek"
			:image="`${baseImageUrl}${gameOfTheWeek?.img}`"
			:image-pattern="imagePattern"
			is-game-of-week
			skipGamePreview
			@toggle-favorite="handleToggleToFavoriteClick(gameOfTheWeek as Game)"
			@play="handlePlay(gameOfTheWeek as Game)"
		/>
		<MGame
			v-for="game in filteredGames"
			:key="game.id"
			:is-guest="!!appInit?.isGuest"
			:game="game"
			:image="`${baseImageUrl}${game.logo || game.img}`"
			:image-pattern="imagePattern"
			:skeleton="'skeleton' in game"
			skipGamePreview
			@toggle-favorite="handleToggleToFavoriteClick(game)"
			@play="handlePlay(game)"
		/>
	</transition-group>
</template>

<style scoped lang="scss">
$games-in-row: 6;
$games-in-row-mob: 3;

.grid-cards {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 8px;
	position: relative;

	@include media-breakpoint-down(md) {
		grid-template-columns: repeat(4, 1fr);
	}

	@include media-breakpoint-down(sm) {
		grid-template-columns: repeat(3, 1fr);
	}

	&--1 {
		& > div {
			&:nth-child(n + #{$games-in-row + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob + 1}) {
					display: none;
				}
			}
		}
	}

	&--2 {
		& > div {
			&:nth-child(n + #{$games-in-row * 2 + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob * 2 + 1}) {
					display: none;
				}
			}
		}
	}

	&--3 {
		& > div {
			&:nth-child(n + #{$games-in-row * 3 + 1}) {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			& > div {
				&:nth-child(n + #{$games-in-row-mob * 3 + 1}) {
					display: none;
				}
			}
		}

		&.with-link {
			& > div {
				&:nth-child(n + #{$games-in-row * 3}) {
					display: none;
				}
			}

			@include media-breakpoint-down(sm) {
				& > div {
					&:nth-child(n + #{$games-in-row-mob * 3}) {
						display: none;
					}
				}
			}
		}
	}

	.week-game {
		width: auto !important;
		grid-column: span 2;
		position: relative;

		@include media-breakpoint-down(sm) {
			grid-column: span 3;
		}

		&:deep(.game-item__badge) {
			top: 45px;
		}

		&::after {
			content: "game of the week";
			position: absolute;
			left: 8px;
			top: 8px;
			background: var(--gradient-10);
			border-radius: 16px;
			font-style: normal;
			padding: 2px 8px;
			font-weight: 700;
			font-size: 10px;
			text-transform: uppercase;
			color: var(--neutral);
			z-index: 1;
			font-family: var(--font-family-secondary);
		}
	}
}

.list-complete {
	.game-wrapper {
		transition: all 0.6s ease;
		display: inline-block;
	}
	.list-complete-enter-from,
	.list-complete-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}

	.list-complete-leave-active {
		position: absolute;
	}
}
</style>
<style lang="scss">
.icon-favorite {
	background-image: url(/assets/icons/20/favorite-notify.svg);
}

.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: gutter(2);
		transform: translateX(0%);
	}
}
</style>
